import Address from '@/application/models/Address.js';
import Contact from '@/application/models/Contact';
import Model from '@/application/models/model.js';
import { LocationInheritSettings } from '@/application/enums/LocationInheritSettings';

class Location extends Model {
  id = 0;
  name = '';
  hasManualFinancial = false;
  iban = '';
  ibanAscription = '';
  vatNumber = '';
  costCenter = '';

  hasManualAddress = true;
  address = new Address();

  hasManualContactDetail = false;
  contactDetail = new Contact();

  locationFinancialAddressInheritSettingsId = LocationInheritSettings.EMPLOYER;
  financialAddress = new Address();

  locationFinancialContactDetailsInheritSettingsId = LocationInheritSettings.EMPLOYER;
  financialContactDetail = new Contact();

  additionalEmails = {
    cc: [],
    bcc: '',
  };

  assignedUsers = [];

  mapForRequest() {
    return {
      name: this.name,
      hasManualFinancial: this.hasManualFinancial,
      iban: this.hasManualFinancial ? this.iban : undefined,
      ibanAscription: this.hasManualFinancial ? this.ibanAscription : undefined,
      vatNumber: this.hasManualFinancial ? this.vatNumber : undefined,
      costCenter: this.costCenter,
      hasManualAddress: this.hasManualAddress,
      address: this.hasManualAddress ? this.address.mapForRequest() : undefined,
      hasManualContactDetail: this.hasManualContactDetail,
      contactDetail: this.hasManualContactDetail ? this.contactDetail.mapForRequest() : undefined,
      locationFinancialAddressInheritSettingsId: this.locationFinancialAddressInheritSettingsId,
      financialAddress: this.locationFinancialAddressInheritSettingsId === LocationInheritSettings.MANUAL ? this.financialAddress.mapForRequest() : undefined,
      additionalEmails: this.additionalEmails,
      locationFinancialContactDetailsInheritSettingsId: this.locationFinancialContactDetailsInheritSettingsId,
      financialContactDetail: this.locationFinancialContactDetailsInheritSettingsId === LocationInheritSettings.MANUAL ? this.financialContactDetail.mapForRequest() : undefined,
      assignedUsers: this.assignedUsers.map((item) => item.id),
    };
  }
}

export default Location;
