<template>
  <div id="location-layout">
    <KSubMenu
      :menu-items="menuItems"
      :parent-menu="parentMenu"
    />
    <RouterView
      v-if="location"
      :employer="employer"
      :get-location="getLocation"
      :location="location"
    />
  </div>
</template>

<script>

import Location from '@/application/models/Location.js';
import KSubMenu from '@/components/menu/KSubMenu.vue';
import { show } from '@/modules/location/api/location.js';

export default {
  name: 'LocationLayout',
  components: {
    KSubMenu,
  },
  props: {
    employer: {
      type: Object,
    },
  },
  data: () => ({
    location: null,
  }),
  computed: {
    menuItems() {
      return [
        {
          title: this.$t('global.dossier'),
          route: { name: 'employer.location.show' },
        },
        {
          title: this.$tc('department.title', 2),
          route: {
            name: 'employer.location.department.index',
          },
          permission: 'department.show',
        },
      ];
    },
    parentMenu() {
      return [
        {
          title: this.$tc('employer.title', 1),
          route: { name: 'employer.index' },
        },
        {
          title: this.$tc('location.title', 1),
          route: {
            name: 'employer.location.index',
            params: { employerId: this.employer.id },
          },
        },
      ];
    },
  },
  watch: {
    '$route.params.locationId': {
      immediate: true,
      handler() {
        this.getLocation();
      },
    },
  },
  methods: {
    async getLocation() {
      const response = await show(this.$route.params.locationId);
      this.location = new Location().mapResponse(response.data.data);
    },
  },
};
</script>
